/** @format */

import React from 'react';
// Custom components
// Material UI
import {styled} from '@material-ui/core/styles';

//#region Styled components

const MainImg = styled('img')(
  {
    flex: '3',
    height: '100%',
    objectFit: 'contain',
    width: 'max-content',
  },
  {name: 'main-banner-img'}
);

const MainImgMobile = styled('img')(
  {
    flex: '3',
    height: '100%',
    objectFit: 'contain',
    width: '90%',
  },
  {name: 'main-banner-img'}
);

const TextWrapper = styled('div')(
  {
    alignItems: props => props.aligntxt ?? 'center',
    display: 'flex',
    flex: '2',
    width:'100%',
    flexDirection: 'column',
    fontSize: props => (props.ismobile ? '4rem' : '2.25rem'),
    justifyContent: props => props.justifytxt ?? 'center',
  },
  {name: 'txt-wrap'}
);

const Wrapper = styled('div')(
  {
    alignItems: props => props.alignitems ?? 'center',
    backgroundColor: props => props.bgcolor ?? '#fff',
    display: 'flex',
    flexDirection: props => props.flowdir ?? 'inherit',
    height: '100vh',
    justifyContent: props => props.justifycontent ?? 'center',
    maxHeight: '1080px',
    padding: props => props.padding ?? '5%',
    width: '100%',
  },
  {name: 'banner-wrap'}
);

const MobileWrapper = styled('div')(
  {
    alignItems: props => props.alignitems ?? 'center',
    backgroundColor: props => props.bgcolor ?? '#fff',
    display: 'flex',
    flexDirection: props => props.flowdir ?? 'inherit',
    justifyContent: props => props.justifycontent ?? 'center',
    maxHeight: '1080px',
    padding: props => props.padding ?? '5%',
    width: '100%',
  },
  {name: 'banner-wrap'}
);

//#endregion

export default function Default(props) {
  const {
    alignItems = 'center',
    bgColor,
    children,
    justifyContent = 'center',
    mainImgSrc = '',
    mainImgStyles = {},
    padding,
    textLoc = 'top',
    txtStyles = {},
    isMobile,
  } = props;

  let flowDir = '';
  switch (textLoc) {
    case 'top':
      flowDir = 'column-reverse';
      break;
    case 'right':
      flowDir = 'row';
      break;
    case 'bottom':
      flowDir = 'column';
      break;
    case 'left':
      flowDir = 'row-reverse';
      break;
  }

  return isMobile ? (
    <MobileWrapper
      alignitems={alignItems}
      padding={padding}
      bgcolor={bgColor}
      flowdir={flowDir}
      justifycontent={justifyContent}>
      <MainImgMobile src={mainImgSrc+"?w=400"} style={{...mainImgStyles}} loading="lazy"/>
      <TextWrapper ismobile={isMobile} style={{...txtStyles}}>
        {children}
      </TextWrapper>
    </MobileWrapper>
  ) : (
    <Wrapper
      alignitems={alignItems}
      padding={padding}
      bgcolor={bgColor}
      flowdir={flowDir}
      justifycontent={justifyContent}>
      <MainImg src={mainImgSrc+"?w=800"} style={{...mainImgStyles}} loading="lazy"/>
      <TextWrapper style={{...txtStyles}}>{children}</TextWrapper>
    </Wrapper>
  );
}
