export default {
    PETG: {
        title:'PETG Filament | Tangle-Free & Color Consistent | PrintBed',
        desc:'Find PETG filament for durable applications from PrintBed. Eco-friendly material is sourced and manufactured in the USA. Start creating your prototypes!'
    },
    FORGE: {
        title:"PLA+ Filament | Manufactured in the USA | PrintBed",
        desc:"Buy high-quality Forge PLA+ filament and have it delivered to your door overnight. PrintBed offers tangle-free spools to ensure smooth printing. Order now."
    },
    ABS:{
        title:"ABS 3D Printing Filament | $10 Overnight Shipping | Printbed",
        desc:"Bring your creative designs to life with high-strength ABS 3D printing filament from PrintBed. Enjoy high performance and reliable printing with ABS spools."
    },
    "Black-1.75mm-1kg": {
        title:"Black PLA Filament | Quality & Color Consistent | PrintBed",
        desc:"Enhance your 3D printing experience with black PLA filament from PrintBed. Tangle-free spools allow you to print creative designs without any issues. Order now."
    },
    "Grey-1.75mm-1kg": {
        title:"Grey PLA Filament | Recyclable Packaging | PrintBed",
        desc:"Use eco-friendly grey PLA filament from PrintBed to create 3D printed objects. Start creating your new designs with tangle-free filaments that print smoothly."
    },
    "White-1.75mm-1kg": {
        title:"White PLA Filament | Made & Sourced in the USA | PrintBed",
        desc:"Get premium white PLA filament from PrintBed. Our filament is manufactured ethically in the USA. Get creating and tag us to demonstrate your prototypes!"
    },
    "Red-1.75mm-1kg":{
        title:"Red PLA 3D Printer Filament | Tangle-Free Spools | PrintBed",
        desc:"Elevate your 3D printing experience with red PLA printer filament from PrintBed. Spools are designed to ensure the filament doesn't get tangled during printing."
    },
    "Yellow-1.75mm-1kg":{
        title:"Yellow 3D Printer Filament | Perfectly Wound | PrintBed",
        desc:"Get yellow 3D printer filament from Printbed. Our spools are perfectly wound to prevent issues during printing. Trusted quality and performance. Order now. "
    },
    "Green-1.75mm-1kg":{
        title:"Green 3D Printer Filament | Recyclable Packaging | PrintBed",
        desc:"Print with high-quality green 3D printer filament from PrintBed. Green PLA filaments are made with sustainable materials. Start creating your 3D prototypes!"
    },
    "Orange-1.75mm-1kg": {
        title:"Orange PLA | Consistent Color for Pristine Prints | PrintBed",
        desc:"Create your 3D prints with high-quality orange PLA filament from PrintBed. Orange 3D printer filament is designed for smooth, tangle-free printing. Order now."
    },
    "Blue-1.75mm-1kg": {
        title:"Blue PLA | Print With Precision and Accuracy | PrintBed",
        desc:"Find premium blue PLA print material produced in the USA with PrintBed. Design with blue 3D printer filament that is consistent in color and performance."
    },
    "Purple-1.75mm-1kg": {
        title:"Purple PLA | High-Quality 3D Printing Filament | PrintBed",
        desc:"Get purple PLA 3D printer filament that never tangles from PrintBed. Bring your invetiveness to reality with filaments that prevent printing issues. Order now."
    },
    "Mint-1.75mm-1kg": {
        title: "Mint PLA Filament | Recyclable Packaging | PrintBed",
        desc:"Mint PLA printing material from PrintBed is made with renewable resources that are eco-friendly. Minimize your environmental impact with high-quality spools."
    },
    "Frost-1.75mm-1kg": {
        title: "Light Blue PLA | Neat and Tangle-Free Filament | PrintBed",
        desc:"Find premium light blue PLA at PrintBed and bring your creative ideas to life. Tangle-free Frost light blue filament allows you to print with ease. Order now."
    },
}