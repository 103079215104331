/** @format */

import React, {useState} from 'react';
// npm Package imports
import parse from 'html-react-parser';
import LinesEllipsis from 'react-lines-ellipsis';
// Material UI Components
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// Material UI Icons
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import PinterestIcon from '@material-ui/icons/Pinterest';
// Styles
import {styled} from '@material-ui/styles';
import ItemStyles from '../styles';
// Custom imports
import BulkOption from './bulkOption.js';
import SelectedBulkSpool from './selectedBulkSpool';
import ItemTabs from '../tabs/tabsContainer';
import ImageViewer from '../image-viewer.js';

const TAG_WHITELIST = ['color', 'diameter'];

//#region Styled Components

const DiameterBtn = styled(Button)(
  {
    border: '2px solid transparent',
    borderRadius: 4,
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    fontSize: '2rem',
    height: '5vh',
    margin: '0 0.5rem 0.5rem 0',
    padding: '0.925vh 1.25rem',
    '&.selected': {
      borderColor: '#24a9df',
    },
  },
  {name: 'diameter-btn'}
);

const SelectedTxtWrapper = styled('div')(
  {
    position: 'absolute',
    right: '-100%',
    width: '100%',
  },
  {name: 'selected-txt-wrap'}
);

//#endregion

export default function BulkComp(props) {
  const pgStyles = ItemStyles();
  const {baseItem, cartUtils, individualRolls, itemId, material} = props;
  const {addItemToCart=()=>{}} = cartUtils;
  const [displayedRolls, setDisplayedRolls] = useState(individualRolls ?? []);
  const [selectedDiameter, setSelectedDiameter] = useState('1.75mm');
  const [selectedRolls, setSelectedRolls] = useState([]);
  const [colorSelection, setColorSelection] = useState({});
  const [quantity, setQuantity] = useState(1);
  const descHeight = 0;
  const allDiameters = [];

  const optSet = new Set();

  //#region Initialization
  // Populating optSet
  individualRolls.forEach(i => {
    // Populating all diameter values
    if (!allDiameters.includes(i?.tags?.diameter)) {
      allDiameters.push(i.tags.diameter);
    }
    let setKey = '';
    for (const tag of TAG_WHITELIST) {
      setKey += i.tags[tag].toLowerCase();
    }
    optSet.add(setKey);
  });

  //#endregion

  function handleSpoolSelect(targetRoll, inc = false) {
    let tempRolls = [...selectedRolls];
    if (selectedRolls.includes(targetRoll) && !inc) {
      tempRolls.splice(tempRolls.indexOf(targetRoll), 1);
    } else if (selectedRolls.length < 10) {
      tempRolls.push(targetRoll);
    }
    let colorQty = {};
    tempRolls.forEach(item => {
      if (!colorQty[item.tags.color]) {
        colorQty[item.tags.color] = 0;
      }
      colorQty[item.tags.color] += 1;
    });
    setColorSelection(colorQty);
    setSelectedRolls(tempRolls);
  }

  function isSelected(item) {
    return selectedRolls.includes(item);
  }

  function vhToPx(vh) {
    let oneVh = (window.innerHeight / 100.0).toFixed(2);
    return oneVh * vh;
  }

  function handleDiameterChange(newDiameter) {
    setSelectedDiameter(newDiameter);
  }

  //#region Tab handlers/vars
  const [tabVal, setTabVal] = useState('Description');
  const tabTitles = ['Description', 'Additional Information'];
  const tabContents =
    baseItem?.desc !== undefined
      ? [
          <Typography variant="body1" component="div" id="full-desc">
            {parse(
              baseItem.desc.replace(
                '</ul>',
                `<li><span>Recommended Temp: ${baseItem.data.temp}° C</span></li></ul>`
              )
            )}
          </Typography>,
          <Typography variant="body1" component="div" id="extra-info">
            {parse(baseItem.extraInfo)}
          </Typography>,
        ]
      : [];

  const handleTabChange = (event, newVal) => {
    setTabVal(newVal);
  };

  const goToDescTab = event => {
    setTabVal('Description');
    scrollToTabs();
  };

  const getDescFirstParagraphHtml = () => {
    let pTagStopIndex = baseItem.desc.indexOf('</p>') + 4;
    return baseItem.desc.slice(0, pTagStopIndex);
  };

  const getDescFirstParagraphText = () => {
    let html = getDescFirstParagraphHtml();
    return html.slice(html.indexOf('>') + 1, html.lastIndexOf('<'));
  };

  const scrollToTabs = () => {
    document.getElementById('scroll-div').scrollIntoView();
    window.scrollBy(0, -vhToPx(10));
  };
  //#endregion

  return (
    <>
      {baseItem !== null ? (
        <div className={pgStyles.wrapper} key={'item-wrapper-' + itemId}>
          <div className={pgStyles.overviewDiv}>
            <ImageViewer
              currentItem={baseItem}
              className={pgStyles.imgViewer}
            />
            <div className="item-details">
              <Typography variant="h5">{baseItem.name}</Typography>
              <LinesEllipsis
                text={getDescFirstParagraphText()}
                maxLine={6}
                className={
                  pgStyles.descContainer +
                  ' MuiTypography-root MuiTypography-body2'
                }
                id="desc-container"
                style={{
                  minHeight: descHeight,
                }}
              />
              <Typography
                variant="body2"
                className={pgStyles.readMore}
                onClick={goToDescTab}>
                Read More
              </Typography>
              <div className={pgStyles.optionWrapper}>
                <Typography variant="body1" className="opt-name">
                  Diameter:&nbsp;
                </Typography>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  {allDiameters.map((val, index) => {
                    return (
                      <DiameterBtn
                        className={val === selectedDiameter ? 'selected' : ''}
                        key={`diameter-${index}`}
                        onClick={() => handleDiameterChange(val)}>
                        {val}
                      </DiameterBtn>
                    );
                  })}
                </div>
                <Typography variant="body1" className="opt-name">
                  Colors:&nbsp;
                </Typography>
                {displayedRolls.map((item, index, array) => {
                  if (item.tags.diameter === selectedDiameter) {
                    return (
                      <BulkOption
                        handleOptionSelect={handleSpoolSelect}
                        item={item}
                        key={item.id}
                        isSelected={isSelected(item)}
                        isDisabled={item.qoh <= 0}
                      />
                    );
                  }
                })}
              </div>
              <div className={pgStyles.optionWrapper}>
                <Typography variant="body1" className="opt-name">
                  Selected:
                </Typography>
                <SelectedBulkSpool
                  handleRollClick={handleSpoolSelect}
                  selectedRolls={selectedRolls}
                />
                <SelectedTxtWrapper
                  style={{position: 'absolute', width: '100%',paddingLeft:'6rem'}}>
                  <table style={{width: '100%', }}>
                    <tbody>
                      {selectedRolls.map((roll, index) => {
                        if (selectedRolls.indexOf(roll) === index) {
                          return (
                            <tr key={`selected-roll-${index}`}>
                              <td style={{width: '50%'}}>
                                <Typography variant="body1">{`${roll.tags.colorName} (${roll.tags.diameter})`}</Typography>
                              </td>
                              <td>
                                <Typography variant="body1">
                                  qty:&nbsp;
                                  {
                                    selectedRolls.filter(
                                      item => item.name === roll.name
                                    ).length
                                  }
                                </Typography>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </SelectedTxtWrapper>
              </div>
            </div>
            <div className={pgStyles.saleInfo}>
              <Typography variant="h4" className={pgStyles.price}>
                ${(baseItem.price / 100).toFixed(2)}
              </Typography>
              <Typography variant="body1" className={pgStyles.availabilityTxt}>
                Availability:&nbsp;
                <span
                  className={
                    pgStyles.itemStock +
                    ' ' +
                    (baseItem.qoh > 0 ? 'in-stock' : 'out-of-stock')
                  }>
                  {baseItem.qoh > 0 ? ' In Stock' : ' Out of Stock'}
                </span>
              </Typography>
              <div className={pgStyles.quantitySelection}>
                <section className="qty-btns">
                  <Button
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity(quantity - 1);
                      } else {
                        setQuantity(1);
                      }
                    }}
                    className={pgStyles.quantityButton + ' left'}
                    disabled={!(quantity > 1)}>
                    &ndash;
                  </Button>
                  <TextField
                    className="qty"
                    InputProps={{disableUnderline: true}}
                    value={quantity}
                    onChange={e => {
                      let qty = Number(e.target.value);
                      if (qty >= 1) setQuantity(qty);
                      else setQuantity(1);
                    }}
                  />
                  <Button
                    onClick={() => setQuantity(quantity + 1)}
                    className={pgStyles.quantityButton + ' right'}
                    disabled={!(quantity < baseItem.qoh)}>
                    +
                  </Button>
                </section>

                <Typography variant="h5" className={pgStyles.price}>Ground Shipping Only*</Typography>
                <Button
                  className={pgStyles.addToCartButton}
                  disabled={!(baseItem.qoh > 0) || selectedRolls.length < 10}
                  disableElevation
                  fullWidth
                  onClick={e => {
                    addItemToCart({
                      id: baseItem.id,
                      quantity: quantity,
                      options: {
                        material: 'Bulk',
                        weight: '200g',
                        diameter: '1.75mm',
                        color: selectedRolls
                          .map(mini => mini.tags.colorName)
                          .join(', '),
                        temps: selectedRolls.map(mini => mini.data.temp),
                      },
                      sides: selectedRolls.map(mini => mini.id),
                    });
                  }}
                  variant="contained">
                  Add to Cart
                </Button>
                <Typography variant="caption" className={pgStyles.groundShipping}>
                *The 10 Pack will always ship ground and will arrive separate of any other products ordered. This item does not qualify for Overnight Everywhere shipping.
                </Typography>
              </div>
              <div className={pgStyles.extraInfo}>
                <div className="info-spacer"></div>
                <div className="info-wrapper">
                  <Typography variant="body1" className="txt">
                    {'Category: '}
                    {material}
                  </Typography>

                  <div className={'txt ' + pgStyles.socialBtns}>
                    <Typography variant="body1" display="inline">
                      Share:
                    </Typography>

                    <div className={'txt ' + pgStyles.socialBtns}>
                      <IconButton
                        className="social-button"
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://www.printbed.com${window.location.pathname}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        <FacebookIcon />
                      </IconButton>
                      <IconButton
                        className="social-button"
                        href={encodeURI(
                          `http://twitter.com/share?text=High Qualtiy PLA for 3d Printing&url=https://www.printbed.com${window.location.pathname}&hashtags=3dprinting,filament,printbed`
                        )}
                        target="_blank"
                        rel="noopener noreferrer">
                        <TwitterIcon />
                      </IconButton>
                      <IconButton
                        href={`http://pinterest.com/pin/create/link/?url=https://www.printbed.com${window.location.pathname}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-button">
                        <PinterestIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ItemTabs
              className={pgStyles.infoTabs}
              titles={tabTitles}
              contents={tabContents}
              curTab={tabVal}
              changeFunc={handleTabChange}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
