/** @format */

import React, {useEffect, useState} from 'react';
// npm Packages
import {Sliders} from 'react-feather';
// Custom components
import Filter, {FilterOpt} from './filters';
import {FilterObj} from './index';
// Material UI
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import {styled} from '@material-ui/core/styles';

//#region Styled Components

const ApplyBtn = styled(Button)(
  {
    backgroundColor: '#ffc905',
    border: '2px solid transparent',
    marginTop: '5rem',
    '&.MuiButton-disableElevation:hover': {
      backgroundColor: '#ffc905',
      border: '2px solid black',
    },
  },
  {name: 'apply-btn'}
);

const Filters = styled(Grid)(
  {
    alignItems: 'flex-end',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: '5rem',
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiAccordion-root::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '0',
    },
  },
  {name: 'filters'}
);

const OpenBtn = styled(Button)({}, {name: 'open-btn'});

const StyledDrawer = styled(Drawer)({}, {name: 'styled-drawer'});

const Wrapper = styled('div')(
  {
    display: 'flex',
  },
  {name: 'filter-modal-wrap'}
);

//#endregion
const categories = [
  "Filament",
  "Parts",
  "Merch"
];
export default function FilterModal(props) {
  const {
    initSelectedFilters = [],
    onClose = () => {},
    tags,
    uiTagBlacklist = [],
  } = props;
  tags.category = categories;
  const [isOpen, setIsOpen] = useState(false);
  const [queryStr, setQueryStr] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);

  // updating selected filters
  useEffect(() => {
    setSelectedFilters([...initSelectedFilters]);
  }, [initSelectedFilters]);

  // Selecting filters
  useEffect(() => {
    let tempQueryStr = '';
    for (let i = 0; i < selectedFilters.length; i++) {
      let curFilter = selectedFilters[i];
      if (!(i === 0)) {
        tempQueryStr = tempQueryStr.concat('&');
      }
      if(curFilter.title==='category') {
        tempQueryStr = tempQueryStr.concat('tags.material=');
        for (let i = 0; i < curFilter.vals.length; i++) {
          let filterVal = curFilter.vals[i];
          if (!(i === 0)) {
            tempQueryStr = tempQueryStr.concat(',');
          }
          switch(filterVal) {
            case 'Filament':
              filterVal = 'PLA,PLA Ltd,PLA Sub,FORGE,PETG';
              break;
            case 'Parts':
              filterVal = 'nozzles,Tubing,parts';
              break;
            case 'Merch':
              filterVal = 'tshirt,Poster';
              break;
          }
          tempQueryStr = tempQueryStr.concat(filterVal);
        }
      } else {
        tempQueryStr = tempQueryStr.concat('tags.', curFilter.title, '=');
        for (let i = 0; i < curFilter.vals.length; i++) {
          const filterVal = curFilter.vals[i];
          if (!(i === 0)) {
            tempQueryStr = tempQueryStr.concat(',');
          }
          tempQueryStr = tempQueryStr.concat(filterVal);
        }
      }
    }
    setQueryStr(tempQueryStr);
  }, [selectedFilters]);

  //#region Functions

  function filterToggle(filterTitle, filterVal) {
    let filterMatch = selectedFilters.find(
      filter => filter.title === filterTitle
    );

    if (filterMatch !== undefined) {
      // Tag is already selected
      const filterIndex = selectedFilters.indexOf(filterMatch);
      const tempSelectedFilters = selectedFilters;
      if (filterMatch.vals.includes(filterVal)) {
        // Tag value is already selected
        if (filterMatch.vals.length <= 1) {
          // Targeted val is the only selected val
          tempSelectedFilters.splice(filterIndex, 1);
        } else {
          filterMatch.removeVal(filterVal);
          tempSelectedFilters.splice(filterIndex, 1, filterMatch);
        }
      } else {
        // Tag value is NOT selected
        filterMatch.addVal(filterVal);
        tempSelectedFilters.splice(filterIndex, 1, filterMatch);
      }
      setSelectedFilters([...tempSelectedFilters]);
    } else {
      // Tag is NOT already selected
      let tempSelectedFilters = selectedFilters;
      tempSelectedFilters.push(new FilterObj(filterTitle, filterVal));
      setSelectedFilters([...tempSelectedFilters]);
    }
  }

  function handleClose() {
    onClose(queryStr);
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  //#endregion

  return (
    <Wrapper>
      <OpenBtn onClick={openModal}>
        <Sliders />
      </OpenBtn>
      <StyledDrawer
        ModalProps={{
          keepMounted: true,
        }}
        onClose={handleClose}
        open={isOpen}
        // variant="persistent"
      >
        <Filters container direction="column" wrap="nowrap">
          {Object.keys(tags).sort().map(tagKey => {
            return uiTagBlacklist.includes(tagKey) ? null : (
              <Filter
                key={`filter-${tagKey}`}
                labels={tagKey === 'color' ? tags['colorName'] : tags[tagKey]}
                onChange={(filterName, filterVal) =>
                  filterToggle(filterName, filterVal)
                }
                selectedFilters={selectedFilters}
                title={tagKey === 'colorName' ? 'color' : tagKey}
                tagKey={tagKey}
                values={tags[tagKey]}></Filter>
            );
          })}
          <ApplyBtn
            disableElevation
            fullWidth
            onClick={handleClose}
            variant="contained">
            Apply
          </ApplyBtn>
        </Filters>
      </StyledDrawer>
    </Wrapper>
  );
}
