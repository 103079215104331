/** @format */

import React from 'react';
// Custom components
import {BannerBtn, Default} from '../index';
// Material UI
import Typography from '@material-ui/core/Typography';
import {styled} from '@material-ui/core/styles';

//#region Styled components
const MainTxt = styled(Typography)(
  {
    color: '#000',
    fontFamily: 'Barlow Condensed, Roboto, sans-serif',
    fontSize: '2.25rem',
    fontWeight: '400',
    letterSpacing: '0',
    lineHeight: '1',
    marginBottom: '3rem',
    userSelect: 'none',
    width: '50rem',
    '&.mbl': {
      width: '100%',
    },
    '&.med-txt': {
      fontSize: '3rem',
      fontWeight: '600',
      marginBottom: '5rem',
      '&.mbl': {
        fontSize: '8rem',
      },
    },
    '&.big-txt': {
      fontSize: '8rem',
      fontWeight: '700',
      marginBottom: '3.5rem',
      '&.mbl': {
        fontSize: '12rem',
      },
    },
    '&.small-txt': {
      fontSize: '3rem',
      fontWeight: '400',
      '&.mbl': {
        fontSize: '9rem',
        width:'100%',
        textAlign:'justify'
      },
      '&.top-line': {
        marginBottom: '2rem',
      },
      '&.long-txt': {
        marginBottom: '5rem',
        width: '85%',
        '&.mbl': {
        width:'100%',
      },
      },
    },
  },
  {name: 'main-txt'}
);

const MinisLogo = styled('img')(
  {
    marginBottom: '3rem',
    padding: '0 10%',
    width: '100%',
  },
  {name: 'minis-logo'}
);
//#endregion

export default function MinisBanner(props) {
  const {isMobile} = props;
  return (
    <Default
      justifyTxt="flex-end"
      bgColor="#ffde00"
      isMobile={props.isMobile}
      mainImgSrc="https://cdn.sunsh1n3.com/home/Minis/Minis.png"
      mainImgStyles={{
        flex: '2 2 0%',
        width: '60%',
      }}
      padding="5%"
      textLoc={isMobile?"top":"left"}
      txtStyles={{
        flex: '1 1 0%',
        marginLeft: '5%',
        marginRight: isMobile?"5%":null,
      }}>
      <MainTxt
        align="center"
        variant="h2"
        className={`small-txt top-line ${props.isMobile ? 'mbl' : ''}`}>
        Introducing
      </MainTxt>
      <MinisLogo src="https://cdn.sunsh1n3.com/home/Minis/Logo.png" />
      <MainTxt
        align="center"
        className={`med-txt ${props.isMobile ? 'mbl' : ''}`}>
        Bundle for $33.00
      </MainTxt>
      <MainTxt
        align="center"
        className={`small-txt long-txt ${props.isMobile ? 'mbl' : ''}`}>
        Our new mini spools are 200g each, making them the perfect size to try new colors. Minis are available in our standard and Limited Edition colors. Pick 5 and bundle for just $33.
      </MainTxt>
      <BannerBtn
        colorVariant="black"
        custHeight={props.isMobile ? '20rem' : undefined}
        custWidth={props.isMobile ? '' : undefined}
        fontSize={props.isMobile ? '8rem' : '2.75rem'}
        fontWeight="400"
        to="/item/minis">
        Order Minis Now
      </BannerBtn>
    </Default>
  );
}
