/** @format */

import {makeStyles} from '@material-ui/core/styles';

const ItemStyles = makeStyles({
  addToCartButton: {
    borderRadius: '8px',
    backgroundColor: '#1899ff',
    color: 'white',
    fontSize: '6rem !important',
    fontWeight: '400',
    height: '100%',
    marginTop: '4rem',
    padding: '2rem 12.5rem',
  },
  backArrow: {
    marginLeft: '3rem',
    padding: '0',
    position: 'relative',
    zIndex: 99,
  },
  details: {
    backgroundColor: '#fff',
    borderRadius: '24px',
    boxShadow: '0px 3px 64px rgba(0, 0, 0, 0.15)',
    margin: '3rem 2rem 4rem',
    padding: '3rem 5.25rem',
  },
  imgs: {},
  list: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: '2.75rem',
    marginRight: '-5.25rem',
    overflowX: 'scroll',
    padding: '0 0 1rem',
    '&.minis': {
      height: '40vw',
      padding: '0 0 2rem',
    },
  },
  miniOptChoice: {
    border: '2px solid transparent',
    borderRadius: '4px',
    backgroundColor: '#fff',
    height: '100%',
    fontWeight: 'bold',
    fontSize: '6rem',
    padding: '1.75rem 2.5rem',
    '&.color': {
      minWidth: '25vw',
      padding: '1.25rem',
      width: '25vw',
      '&.Mui-disabled': {
        filter: 'opacity(25%)',
      },
    },
    '& .color-div': {
      borderRadius: '4px',
      height: '100%',
      width: '100%',
    },
    '& .MuiButton-label': {
      height: '100%',
    },
  },
  miniOptContainer: {
    alignItems: 'center',
    borderRadius: '4px',
    display: 'flex',
    flex: '0 0 15%',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 2rem 0 0',
    '& .selected': {
      borderColor: '#24a9df',
    },
  },
  miniQtyBtn: {
    backgroundColor: '#f6f6f6!important',
    border: '2px solid #fff',
    borderRadius: '50%',
    height: '8rem',
    minHeight: '0',
    minWidth: '0',
    width: '8rem',
    '&.minus': {
      marginLeft: '-2rem',
    },
    '&.plus': {
      marginRight: '-2rem',
    },
  },
  optionCard: {
    '&.actions': {
      alignItems: 'center',
      backgroundColor: '#f6f6f6',
      display: 'flex',
      flexDirection: 'row',
      height: '12rem',
      justifyContent: 'space-around',
      padding: '0',
      position: 'relative',
      width: '20vw',
      '& .MuiTypography-root': {
        margin: '0 1rem',
        zIndex: 1,
      },
    },
    '&.content': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      margin: '0',
      padding: '0',
      width: '100%',
    },
  },
  optionChoice: {
    border: '2px solid transparent',
    borderRadius: '4px',
    backgroundColor: '#f5f5f5',
    height: 45,
    fontWeight: 'bold',
    fontSize: '4rem',
    padding: '1.75rem 2.5rem',
    '& .color-div': {
      width: '100%',
      height: '100%',
      borderRadius: '4px',
    },
    '&.color': {
      // height: '8rem',
      height: 65,
      // minWidth: '14.75rem',
      padding: '1.25rem',
      width: '8rem',
      '&.Mui-disabled': {
        filter: 'opacity(25%)',
      },
    },
    '& .MuiButton-label': {
      height: '100%',
      width: '100%',
    },
    '&.selected': {
      borderColor: '#24A9DF',
    },
    '.selected &': {
      borderColor: '#24A9DF',
    },
  },
  optionContainer: {
    alignItems: 'center',
    borderRadius: '4px',
    display: 'flex',
    flex: '0 0 15%',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 2rem 0 0',
    '& .selected': {
      borderColor: '#24A9DF',
    },
  },
  optionWrapper: {
    display: 'flex',
    flexWrap: 'none',
    maxWidth: '54rem',
    '& .opt-name': {
      margin: '0',
      textTransform: 'capitalize',
      flexGrow: '1',
      flexBasis: '100%',
      userSelect: 'none',
    },
    '&:last': {
      marginBottom: '10vh',
    },
  },
  optTitle: {
    fontSize: '6rem',
    marginBottom: '2rem',
    textTransform: 'capitalize',
  },
  qtySelect: {
    border: '1px solid #707070',
    borderRadius: '8px',
    fontSize: '6rem',
    '& .select': {
      padding: '1rem 4rem 1rem 6rem',
      // margin: '0.25rem 2rem',
    },
    width: 'max-content',
  },
  selectForm: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& .label': {
      color: '#000',
      height: '100%',
      left: '1.5rem',
      position: 'relative',
      transform: 'scale(1)',
      width: '0',
    },
    '& .mui-native-select': {
      marginTop: 0,
    },
  },
  titles: {
    margin:'4rem 1rem',
    fontSize: '6rem',
    padding: '0',
    '& .color': {},
    '& .other': {
      fontSize: '6rem',
      fontWeight:'bold'
    },
  },
  topTitles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    backgroundColor: '#fff',
    height: 'max-content',
    margin: 0,
    paddingTop: '8rem',
    paddingBottom: '3rem',
    '&.safari-pad': {
      paddingBottom: '12rem',
    },
  },
});

export default ItemStyles;

export const ImageStyles = makeStyles({
  dialog: {
    height: '100vh',
    width: '100vw',
    backdropFilter: 'blur(10px)',
    '& .dialog-paper': {
      height: '100vh',
      width: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      margin: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      backgroundColor: 'transparent',
      '& .nav-btn': {
        backgroundColor: '#fff',
        border: '1px solid #eee',
        boxShadow: '1px 2px 5px #00000008',
        padding: '1rem',
        height: '100%',
        width: '100%',
        maxHeight: '5rem',
        maxWidth: '5rem',
        minWidth: '0',
        '&#fs-prev': {
          marginRight: '3.5rem',
        },
        '&#fs-next': {
          marginLeft: '3.5rem',
        },
        '&.Mui-disabled': {
          filter: 'opacity(25%)',
        },
        '& img': {
          height: '100%',
          width: '100%',
        },
      },
    },
  },
  imagePreviews: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingInlineStart: 0,
    overflow: 'hidden',
    width: '100%',
    // margin: 0,
    '.dialog-paper &': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'visible',
      width: '100%',
      backgroundColor: '#fff',
      padding: '2rem 0',
      margin: 0,
    },
  },
  itemImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  itemVideo: {
    width: '100%',
    height: '100%',
    maxWidth: '15rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mainImg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: '2rem 1.5rem',
    // height: '100%',
    maxHeight: '100%',
    position: 'relative',
    flexGrow: '1',
    '&:before': {
      content: '',
      display: 'block',
      paddingTop: '100%',
    },
    '& .img-wrapper': {
      height: '100vw',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        height: 'max-content',
        maxHeight: '100%',
        maxWidth: '100%',
      },
      '& video': {
        height: '100%',
        maxWidth: '100%',
      },
    },
    '.dialog-paper &': {
      height: '75vh',
      backgroundColor: 'transparent',
      width: '100%',
      margin: 0,
      padding: 0,
    },
  },
  minimize: {
    position: 'absolute',
    top: '2.5rem',
    right: '2.5rem',
  },
  navBtns: {
    display: 'flex',
    height: '5rem',
    width: '100%',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 0,
    marginBottom: '1.5rem',
    padding: '0 1rem',
    '& .btn': {
      height: '5rem',
      width: '5rem',
      minWidth: '1rem',
      backgroundColor: '#fff',
      marginLeft: '1rem',
      '&.Mui-disabled': {
        filter: 'opacity(25%)',
      },
      '& img': {
        height: '100%',
        width: '100%',
      },
    },
    '& .fullscreen': {
      flexGrow: '1',
    },
  },
  thumbnailWrapper: {
    height: '10rem',
    width: '10rem',
    backgroundColor: '#fff',
    borderRadius: '4px',
    marginBottom: '2.5rem',
    listStyleType: 'none',
    border: '2px solid #eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '.dialog-paper &': {
      marginBottom: 0,
      height: '100%',
      marginRight: '1.5rem',
      marginLeft: '1.5rem',
    },
    '&.current': {
      border: '2px solid #24A9DF',
      listStyleType: 'none',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1.5',
    '& .dialog': {
      height: '75vh',
      width: '60vw',
    },
  },
});

export const SirvStyles = makeStyles({
  wrapper: {
    width: '100vw',
    minHeight: 220,
    '& #sirv-viewer': {
      height: 'max-content',
      // height: '42.66vh',
      maxHeight: '42vh!important',
    },
    '& .smv-slide.smv-shown': {
      minHeight: '100%',
      minWidth: '100%',
      position: 'relative!important',
    },
    '& .smv-slides': {
      display: 'flex!important',
      minHeight: '32.4vh',
      position: 'relative!important',
    },
    '& .sirv-spin': {
      minHeight: '32.4vh',
    },
    '& .smv-slides-box.smv-h': {
      display: 'flex!important',
      flexGrow: '1',
      height: 'max-content!important',
      justifyContent: 'center',
      padding: '0 8rem',
    },
    '& .smv-button-fullscreen-open .smv-icon': {
      WebkitMaskImage:
        'url(https://scripts.sirv.com/sirvjs/v3/graphics/buttons/fullscreen.03.svg)!important',
    },
    '& .smv-selectors': {
      minHeight: '0!important',
    },
    '& .smv-selectors-box': {
      marginTop: '1rem',
    },
  },
});

export const SelectStyles = makeStyles({
  btn: {
    backgroundColor: '#f5f5f5',
    border: '1px solid #707070',
    borderRadius: '8px',
    padding: '1.5rem 1.5rem',
    '& .val': {
      marginRight: '1.75rem',
    },
    '& .MuiButton-label p': {
      fontSize: '5rem',
    },
  },
  itemWrapper: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #f0f0f0',
    padding: '0',
    position: 'relative',
    '&.selected': {
      backgroundColor: '#F2F9FF',
      borderTop: '1px solid #707070',
      borderBottom: '1px solid #707070',
    },
    '& .icon': {
      width: '25%',
    },
    '& #sel-color': {
      backgroundColor: '#1797FD',
      height: '100%',
      width: '6rem',
    },
  },
  selectedIcon: {
    height: '6rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '0',
    minWidth: 0,
    width: '1rem',
  },
  titleWrapper: {
    backgroundColor: '#f5f5f5',
    padding: '1.5rem 2rem',
    fontSize: '6rem',
    '& .MuiTypography-h6': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      width: '100%',
      '& p': {
        fontSize: '6rem',
      },
    },
  },
  txt: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginLeft: '2.25rem',
    '& span': {
      fontSize: '5rem',
    },
  },
  wrapper: {
    '& .MuiPaper-root': {
      border: '1px solid #707070',
      borderRadius: '8px',
      maxHeight: '55vh',
      width: '51.4vw',
    },
  },
});

export const DescModalStyles = makeStyles({
  dialogBtn: {
    color: '#265EFE',
    fontSize: '6rem',
    marginTop: '2rem',
    padding: '0',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '2rem',
  },
  paper: {
    height: '69.71vh',
  },
  txtTitle: {
    color: '#265EFE',
  },
  txtWrapper: {
    color: '#707070',
    fontSize: '6rem',
    letterSpacing: '-0.28px',
    lineHeight: '21px',
    padding: '5.25rem',
    paddingTop: '2.25rem',
    '& p': {
      marginTop: '3rem',
    },
  },
  wrapper: {},
});
